import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { NbAuthJWTToken, NbAuthService } from "@nebular/auth";
import { NbGlobalPhysicalPosition, NbToastrService } from "@nebular/theme";
import { Observable } from "rxjs";
import "rxjs/add/operator/do";

@Injectable()
export class BearerInterceptor implements HttpInterceptor {
    constructor(private authService: NbAuthService, private router: Router, private toastrService: NbToastrService) {

    }
    intercept(
        req: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        let userToken: string = '';

        this.authService.onTokenChange()
            .subscribe((token: NbAuthJWTToken) => {
                if (token.isValid) {
                    userToken = token.getValue();
                }
            })

        if (userToken == '') {
            return next.handle(req);
        } else {
            //console.log('token: ' + userToken);
            req = req.clone({
                headers: req.headers.set('Authorization', 'Bearer ' + userToken),
            });
            return next.handle(req).do(evt => {
                if (evt instanceof HttpResponse) {
                    //console.log('From http interceptor: ' + evt);
                }
            });
        }
    }
}