import { Component } from '@angular/core';

@Component({
  selector: 'ngx-footer',
  styleUrls: ['./footer.component.scss'],
  template: `
    <span class="created-by">
      Customized with ♥ by <b><a href="https://dhitva.com/" target="_blank">Dhitva</a></b> 2022
    </span>
    <div class="socials">\
      <a href="https://www.facebook.com/dhitva.media/" target="_blank" class="ion ion-social-facebook"></a>
      <a href="https://twitter.com/Dhitvamedia?t=HFdPbAYzB8I9bQIXbZ06DQ&s=09" target="_blank" class="ion ion-social-twitter"></a>
      <a href="https://www.linkedin.com/company/the-dhitva" target="_blank" class="ion ion-social-linkedin"></a>
    </div>
  `,
})
export class FooterComponent {
}
