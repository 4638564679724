import { Injectable } from "@angular/core";
import { CanActivate, Router } from '@angular/router';
import { NbAuthService, NbTokenService } from '@nebular/auth';
import { tap } from 'rxjs/operators';

@Injectable()
export class AuthGuard implements CanActivate {
    constructor(private authService: NbAuthService, private router: Router, private tokenService: NbTokenService,) {

    }

    canActivate() {
        return this.authService.isAuthenticated()
            .pipe(tap(authenticated => {
                if (!authenticated) {

                    sessionStorage.clear();
                    localStorage.clear();
                    this.tokenService.clear();
                    this.router.navigate(['auth/login']);
                }
            }),
            );
    }
}