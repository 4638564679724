import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AnyCnameRecord } from "dns";
import { Observable, Subject, throwError } from "rxjs";
import { catchError, tap } from "rxjs/operators";
import { Api } from "../models/base/api.model";
import { User, UserMainData } from "../models/user/user.module";
import { environment } from "../../environments/environment";

@Injectable({
    providedIn: 'root',
})

export class UserService {
    userInfo: any;
    baseUrl = environment.BASE_URL + 'api/';
    error = new Subject<string>();

    constructor(private httpClient: HttpClient) { }

    private handleError<T>(operation = 'operation', result?: T) {
        return (error: any): Observable<T> => {
            this.error.next(error.message);
            return throwError(error);
        };
    }


    getUsers(): Observable<any[]> {
        let abc = this.httpClient.get<any[]>(`${this.baseUrl}users/`)
            .pipe(
                tap(),
                catchError(this.errorManagement)
            );
        abc.subscribe(res => {
            //console.log('Response: ', res);
        });

        return abc;
    }




    errorManagement(error: HttpErrorResponse) {
        let errorMessage = '';
        if (error.error instanceof ErrorEvent) {
            errorMessage = error.error.message;
        } else {
            errorMessage = 'Error code: ' + error.status + '\nMessage: ' + error.message;
        }
        //console.log(errorMessage);
        return throwError(errorMessage);
    }

    updateUserByAdmin(id: number, data: UserMainData): Observable<Api<UserMainData>> {
        const url = `${this.baseUrl}user/update/${id}/`
        let abc = this.httpClient.put<Api<UserMainData>>(url, data).pipe(
            tap(),
            catchError(this.handleError<Api<UserMainData>>('update')),
        );

        return abc;
    }

}